import React from 'react'

import "../styles/certs.css"
import IMGS from '../assets'


const Certs = () => {
  return (
    <>
      <div className="certificates">
        <div className="certs-container">
          <div className="certa-heading">
            <h2>Certificates & Awards</h2>
            <p>Being an international broker, TradingView Guilds has been honoured with numerous prestigious awards in the sphere of finance.
              The representatives of the company regularly take part in thematic exhibitious and other events devoted to the issues of the currency
              and equities markets development. The partners of TradingView Guilds are entitled to refer to the presented on this page certificates
              to warrant the top quality and high business reputation of the company.
            </p>
          </div>
          <ul>
            <li>
              <div className="img-cont">
                <img src={IMGS.forexpo} alt="#" />
              </div>
              <div className="certs-info">
                <h3>Best Broker Asia Pacific 2015 certificate by Shanghai Forex Expo</h3>
                <p>Shanghai Forex Expo 2015 is one of the most prestige events in the financial industry.
                  Traders, retail and institutional investors, brokerage companies and their partners traditionally
                  take part in the exposition. At Shanghai Forex Expo 2015, TradeView Guilds was honored in the category
                  of The Best Broker Asia Pacific 2015. Expert recognition of the company`s flawless performance proves
                  that TradeView Guilds is a reliable partner for thousands of clients from Asian countries.</p>
              </div>
            </li>
            <li>
              <div className="img-cont">
                <img src={IMGS.ecn13} alt="#" />
              </div>
              <div className="certs-info">
                <h3>Best Global Retail FX Broker certificate by European CEO</h3>
                <p>London-based edition European CEO has awarded TradeView Guilds the Best Global Retail FX Broker 2013 in the
                  framework of its annual European CEO Awards. The award certificate is yet more proof that TradeView Guilds has
                  chosen the right path for achieving this privileged position in the international arena. It is worth noting
                  that European CEO certificate is the second one for TradeView Guilds in the last three years. In 2011 the British
                  magazine also named TradeView Guilds the best retail broker. European CEO Awards recognize financial industry leaders
                  across more than 20 categories. It is held once a year and said to be one of the most reputable and reliable
                  awards. Today, European CEO is a premier edition devoted to major businesses. European CEO reaches an audience
                  of more than 100,000 online and print readers every month.
                </p>
              </div>
            </li>
            <li>
              <div className="img-cont">
                <img src={IMGS.aiair} alt="#" />
              </div>
              <div className="certs-info">
                <h3>Best Retail Forex Broker certificate by IAIR Awards</h3>
                <p>TradeView Guilds was recognized as The Best Forex Broker for 2012 by IAIR Awards in early 2013. Apart from the award,
                  made of cast glass with lettering, TradeView Guilds was granted an official certificate acknowledging IAIR Awards receiving
                  in The Best Retail Forex Broker category.The certificate obtained in Milan is of great importance for TradeView Guilds.
                  Above all, it is the first company's award in 2013. Besides, TradeView Guilds was highly commended by the independent and
                  competent jury for rendering top-notch services. TradeView Guilds extends its appreciation to company's supporters as well
                  IAIR Awards organizers and, undoubtedly, numerous customers whose loyalty was instrumental for certificate and award
                  receiving.TradeView Guilds, in its turn, will make every effort to meet all modern market requirements and continue evolving
                  in response to customers' needs.
                </p>
              </div>
            </li>
            <li>
              <div className="img-cont">
                <img src={IMGS.arabcom} alt="#" />
              </div>
              <div className="certs-info">
                <h3>Best Retail FX Provider 2011 award</h3>
                <p>TradeView Guilds was honoured with the prestigious Best Retail FX Provider Award 2011 at the
                  financial exhibition organized by Arabcom Group.The exhibition was held in Dubai on November 15 - 16, 2011.                  
                  <br />
                  Arabcom Group, an organizer of large - scale exhibitions in the Gulf countries, awarded TradeView Guilds the Best Retail FX Provider title in 2011.
                  It is another top award that proves the professionalism of the company`s employees and the service quality.
                  <br />
                  The success of TradeView Guilds is ensured by willingness to meet the needs of each customer and partner.Individual approach and a wide range of brokerage
                  services give every trader an opportunity to choose the most convenient trading tools and help to develop his own Forex trading strategy.The service of
                  international level allows the company to hold the leading position on the international currency market for many years.More than 500,000 Forex traders have
                  already chosen TradeView Guilds.The prestigious awards show how high the customers and experts from all over the world assess the company.
                </p>
              </div>
            </li>
            <li>
              <div className="img-cont">
                <img src={IMGS.ecn11} alt="#" />
              </div>
              <div className="certs-info">
                <h3>EUROPEAN CEO certificate</h3>
                <p>EUROPEAN CEO, an authoritative London magazine, honoured TradeView Guilds with the Best Retail Broker 2011 title.This certificate is additional proof that
                  TradeView Guilds is constantly upgrading the level of private investors` (customers`) service and employs a flexible approach to individual task solution.
                  <br />
                  Working with TradeView Guilds on financial markets, a trader whatever his experience may be is free to choose the most convenient tools and build an optimum investment strategy.
                  With TradeView Guilds, your opportunities are boundless!
                  <br />
                  EUROPEAN CEO is a VIP magazine providing its readers with up - to - date information essential for taking professional management decisions: news, statistics, detailed analytical reviews,
                  interviews with authorities and CEOs of large corporations.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Certs