import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import { BsPersonFill } from 'react-icons/bs'
import { FiChevronDown } from "react-icons/fi"
import { SiGooglemessages } from 'react-icons/si'
import Logo from './Logo';

const Desktop = ({ userDets, logout, navbar, unread, account }) => {
  const [profile, setProfile] = useState(false);
  const [Tranzact, setTransact] = useState(false);
  const [isRefers, setIsRefers] = useState(false);

  const handleMouseOver = () => {
    setProfile(!profile);
  };

  const OnTranzact = () => {
    setTransact(!Tranzact);
  };
  return (
    <>
      <header className={"header laptop" + (navbar ? " active" : " ")}>
        <Logo />
        {
          userDets ?
            (
              <>

                <ul className="nav-links">
                  <li><Link to={`/${userDets.result.userName}/dashboard/${userDets.result._id}`}>Dashboard</ Link></li>
                  <li><Link to={`/${userDets.result.userName}/live-trading`}>Live Trade</ Link></li>
                  <li onClick={OnTranzact}><Link >Transactions <FiChevronDown /></ Link>
                    <ul className={Tranzact ? "tranzact-menu" : " "}>
                      <li><Link to={`/${userDets.result.userName}/withdrawal/${userDets.result._id}`}>Withdraw</ Link></li>
                      <li><Link to={`/${userDets.result.userName}/deposit/${userDets.result._id}`}>Deposit</ Link></li>
                      <li><Link to={`/${userDets.result.userName}/transfer/${userDets.result._id}`}>Transfer</ Link></li>
                      <li><Link to={`/${userDets.result.userName}/history/${userDets.result._id}`}>History</ Link></li>
                    </ul>
                  </li>
                  <li><Link to='/updates'>Updates</ Link></li>
                  <li><Link to="/certifications" >Certifications</Link></li>
                  <li><Link to='/support'>Support</ Link></li>
                </ul>
                <ul className="nav-options">
                  <li onClick={handleMouseOver}>
                    <div className="header-prof">
                      {
                        (unread > 0 ?
                          <div className={"unrdmsgs" + (unread > 0 ? ' wrn' : ' ')}>
                            <SiGooglemessages />
                            <p>{unread}</p>
                          </div> : <BsPersonFill />
                        )
                      }
                      <span>{userDets.result.userName} <FiChevronDown /></span>
                    </div>
                  </li >
                  <ul className={profile ? "prof-menu" : " "}>
                    <li><Link to={`/${account?._id}/liveChat`} className={'' + (unread > 0 ? ' wrn' : ' ')}>Admin: {unread} messages</Link></li>
                    <li><Link to={`/${userDets.result.userName}/profile-setting/${userDets.result._id}`}>Profile Settings</ Link></li>
                    <li onClick={(e) => setIsRefers(!isRefers)}> <Link >Referrals <FiChevronDown /></ Link></li >
                    <ul className={"refs " + (isRefers ? "exp" : "colp")}>
                      <li onClick={(e) => setProfile(false)}><Link to={`/${userDets.result.userName}/referrals/referred/${userDets.result._id}`}>Referred Users</ Link></li>
                      <li onClick={(e) => setProfile(false)}><Link to={`/${userDets.result.userName}/referrals/commission/${userDets.result._id}`}>Referral Commission</ Link></li>
                    </ul>

                    <li><Link to={`/${userDets.result.userName}/change-password/${userDets.result._id}`}>Change Password</ Link></li>
                    <li><Link to='/' onClick={logout} >Sign Out</ Link></li>

                  </ul >
                </ul >
              </>
            ) :
            (
              <>
                <ul className="nav-links">
                  <li><Link to="/"> Home </Link></li>
                  <li ><Link to="/updates" > Updates </Link></li>
                  <li><Link to='/certifications'>Certifications</ Link></li>
                  <li ><Link to="/support" > Support </Link></li>
                  <li ><Link to="/" > NewsLetter </Link></li>
                </ul>
                <ul className="nav-options optt">
                  <li><Link to="/auth" className='authlink'> Sign In </Link></li>
                </ul>
              </>
            )
        }
      </header >
    </>
  )
}

export default Desktop