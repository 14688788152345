import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getAccounts } from "./components/database/actions/account";

import './App.css';

import Header from './components/header/templates/Header';
import Index from './components/home/templates/Index';
import Footer from './components/footer/templates/Footer'
import Auth from './components/auth/templates/Auth'
import Dashboard from "./components/dashboard/templates/Dashboard";
import Deposit from "./components/deposit/templates/Deposit";
import PaymentGate from "./components/deposit/templates/PaymentGate";
import Withdrawal from "./components/withdraw/templates/Withdrawal";
import Transactions from "./components/transactions/templates/Transactions";
import Reffered from "./components/referrals/templates/Reffered";
import Commissions from "./components/referrals/templates/Commissions";
import ProfSettings from "./components/profile/templates/ProfSettings";
import Transfer from "./components/transactions/templates/Transfer";
import ChangePassword from "./components/profile/templates/ChangePassword";
import History from "./components/transactions/templates/History";
import Support from "./components/support/templates/Support";
import Register from "./components/auth/templates/Register";
import Updates from "./components/support/templates/Updates";
import LiveTrading from "./components/LiveTrading/templates/LiveTrading";
import LiveChat from "./components/dashboard/templates/LiveChat";
import Loaded from "./components/others/templates/Loaded";
import Welcome from "./components/dashboard/templates/Welcome";
import Certs from "./components/certificates/templates/Certs";


function App() {
  const [liveC, setLiveC] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);

  const [preLoader, setPreLoader] = useState(false)
  useEffect(() => {
    setPreLoader(true)
    setTimeout(() => {
      setPreLoader(false)
    }, 6000)
  }, [])

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <BrowserRouter>
      {
        preLoader ? <Loaded /> :
          <>
            <div className="wrapper">
              <div className="igt">
                <div id="google_translate_element"></div>
                <Header liveC={liveC} setLiveC={setLiveC} />
              </div>
              <Routes>
                <Route exact path="/" element={<Index />} />
                <Route exact path="/auth" element={<Auth />} />
                <Route exact path="/signup" element={<Register />} />
                <Route exact path="/:user/complete-setup" element={<Welcome />} />
                <Route exact path="/:user/dashboard/:id" element={<Dashboard />} />
                <Route exact path="/:user/live-trading" element={<LiveTrading />} />
                <Route exact path="/:user/withdrawal/:id" element={<Withdrawal />} />
                <Route exact path="/:user/transfer/:id" element={<Transfer />} />
                <Route exact path="/:user/transactions/:id" element={<Transactions />} />
                <Route exact path="/:user/history/:id" element={<History />} />
                <Route exact path="/:user/deposit/:id" element={<Deposit />} />
                <Route exact path="/:user/deposit/confirm/:amount/:payable" element={<PaymentGate />} />
                <Route exact path="/:user/referrals/referred/:id" element={<Reffered />} />
                <Route exact path="/:user/referrals/commission/:id" element={<Commissions />} />
                <Route exact path="/:user/profile-setting/:id" element={<ProfSettings />} />
                <Route exact path="/:user/change-password/:id" element={<ChangePassword />} />
                <Route exact path="/updates" element={<Updates />} />
                <Route exact path="/support" element={<Support />} />
                <Route exact path="/certifications" element={<Certs />} />
                <Route exact path="/:userID/liveChat" element={<LiveChat />} />
              </Routes>
              <Footer />
            </div>
          </>
      }
    </BrowserRouter>
  );
}

export default App;
