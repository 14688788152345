import aiair from "./aiair.jpg";
import arabcom from "./arabcom.jpg";
import ecn11 from "./ecn11.jpg";
import ecn13 from "./ecn13.jpg";
import forexpo from "./forexpo.jpg";

const IMGS = {
  aiair: aiair,
  arabcom: arabcom,
  ecn11: ecn11,
  ecn13: ecn13,
  forexpo: forexpo,

};

export default IMGS;